<template>
  <div class="home"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'hoto-qrcode',
  components: {},
  data () {
    return {
      loading: false,
      value: '',
      value1: ''

    }
  },
  created () {
    // const isWeChat = this.isWeixin()
    // if (isWeChat) {
    //   window.location.href =
    //     'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzUyNTg3NzEyNw==&scene=124#wechat_redirect'
    // } else {
    //   window.location.href = 'https://www.instagram.com/hoto_official/'
    // }
    window.location.href = 'https://hototools.com/'
  },
  methods: {
    isWeixin () {
      // 是否是微信浏览器
      if (/(micromessenger)/i.test(navigator.userAgent)) {
        // 是否电脑微信或者微信开发者工具
        if (
          /(WindowsWechat)/i.test(navigator.userAgent) ||
          /(wechatdevtools)/i.test(navigator.userAgent)
        ) {
          return true
        } else {
          // 手机微信打开的浏览器
          return true
        }
      } else {
        return false
      }
    },
    getIp () {
      axios
        .get('https://api.myip.la/en?json')
        .then((response) => {
          console.log(response)
          if (response) {
            if (response.data.location.country_code !== 'CN') {
              // window.location.href = 'https://www.instagram.com/hoto_official/'
            } else {
              // window.location.href =
              // 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzUyNTg3NzEyNw==&scene=124#wechat_redirect'
            }
          }
        })
        .catch((err) => {
          console.log('test')
          this.value = err
          // window.location.href =
          //   'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzUyNTg3NzEyNw==&scene=124#wechat_redirect'
        })
        .finally(() => {
          console.log('finally')
          // window.location.href =
          //   'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzUyNTg3NzEyNw==&scene=124#wechat_redirect'
        })
    }
  }
}
</script>
